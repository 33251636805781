function App() {
	return (
		<div className='App'>
			<h1>RoWo Soft</h1>
			<h5>connecting people and software</h5>
			<br />
			<p>
				Contact: <a href='mailto:info@rowosoft.nl'>info@rowosoft.nl</a>
			</p>
		</div>
	);
}

export default App;
